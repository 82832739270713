import React from "react";
import {
    useCustom,
    useGetIdentity,
    useOne,
    useTranslate,
} from "@refinedev/core";
import { Typography } from "antd";
import { useApiUrl } from "@refinedev/core";
import { IIdentity } from "authProvider";
import ReactMarkdown from "react-markdown";
import { ThemedTitleV2 } from "components/layout/title";

const { Title, Paragraph } = Typography;

const ExpiredPage: React.FC = () => {
    const apiUrl = useApiUrl();

    const t = useTranslate();

    const { data: identity } = useGetIdentity<IIdentity>();

    const { data: userGroup } = useOne({
        resource: "user-groups",
        id: identity?.user_group,
        queryOptions: {
            enabled: !!identity?.user_group,
        },
    });

    const { data: settings } = useCustom({
        url: `${apiUrl}/settings/`,
        method: "get",
    });

    const { trial_expired_text, subscription_expired_text } =
        settings?.data || {};

    const isTrialExpired = userGroup?.data?.is_trial;
    const title = isTrialExpired
        ? t("expired.trialTitle")
        : t("expired.subscriptionTitle");
    const expiredText = isTrialExpired
        ? trial_expired_text
        : subscription_expired_text;

    const expirationDate = userGroup?.data?.subscription_expiration_date;

    const PageTitle = (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "32px",
                fontSize: "20px",
            }}
        >
            <ThemedTitleV2 collapsed={false} />
        </div>
    );

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
            {PageTitle}
            <div className="max-w-md p-8 bg-white rounded-lg shadow-md">
                <Title level={2} className="text-center mb-6">
                    {title}
                </Title>
                <Paragraph className="text-center mb-6">
                    {t(
                        "yourTrialOrSubscriptionHasExpiredOn",
                        "Your {{type}} has expired on {{date}}",
                        {
                            type: isTrialExpired
                                ? t("expired.trial")
                                : t("expired.subscription"),
                            date: new Date(expirationDate).toLocaleDateString(
                                "sl-SI"
                            ),
                        }
                    )}
                </Paragraph>
                <ReactMarkdown>{expiredText}</ReactMarkdown>
            </div>
        </div>
    );
};

export default ExpiredPage;
