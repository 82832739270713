import React, { useState } from "react";
import { useTitle, useMenu, useOne, useList, usePermissions } from "@refinedev/core";
import { ThemedTitleV2, useThemedLayoutContext } from "@refinedev/antd";
import { LeftOutlined, RightOutlined, BookOutlined } from "@ant-design/icons";
import { Layout, Menu, Grid, Drawer, Button, theme, Progress } from "antd";
import type { RefineThemedLayoutV2SiderProps } from "@refinedev/antd";
import { ProgressProps } from "antd/lib";
import { useTranslation } from "react-i18next";
import { useGetIdentity } from "@refinedev/core";
import { IIdentity } from "../../authProvider";
import { ContentPageType } from "pages/content-pages/types";
import { useCurrentTenant } from "../../tenants";
import { Feature } from "utilities/availableFeatures";
import type { MenuProps } from "antd";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

type MenuItem = Required<MenuProps>["items"][number] & {
    isAdminOnly?: boolean;
};

export const ThemedSiderV2: React.FC<RefineThemedLayoutV2SiderProps> = ({
    Title: TitleFromProps,
    meta,
    fixed,
}) => {
    const { token } = theme.useToken();
    const {
        siderCollapsed,
        setSiderCollapsed,
        mobileSiderOpen,
        setMobileSiderOpen,
    } = useThemedLayoutContext();

    const TitleFromContext = useTitle();
    const { menuItems, selectedKey, defaultOpenKeys } = useMenu({ meta });
    const breakpoint = Grid.useBreakpoint();

    const isMobile =
        typeof breakpoint.lg === "undefined" ? false : !breakpoint.lg;

    const RenderToTitle = TitleFromProps ?? TitleFromContext ?? ThemedTitleV2;

    const navigate = useNavigate();

    const { data: topLevelPages } = useList({
        resource: "content-pages",
        filters: [{ field: "parent", operator: "eq", value: "null" }],
    });

    const location = useLocation();
    const currentPath = location.pathname;
    const { features: tenantFeatures } = useCurrentTenant();

    const isContentPageSelected = currentPath.startsWith(
        "/content-pages/show/"
    );
    const [selectedContentPageKey, setSelectedContentPageKey] = useState<
        string | undefined
    >(undefined);

    const contentPagesItems = tenantFeatures.includes(Feature.ContentPages)
        ? topLevelPages?.data?.map((page: ContentPageType) => ({
              key: `page-${page.id}`,
              icon: page?.icon ? (
                  <img src={page.icon} width="14px" height="14px" />
              ) : (
                  <BookOutlined />
              ),
              label: (
                  <Link
                      to={`/content-pages/show/${page.id}`}
                      className="text-current no-underline block w-full h-full"
                      onClick={() =>
                          setSelectedContentPageKey(`page-${page.id}`)
                      }
                  >
                      {page.title}
                  </Link>
              ),
              children:
                  page.children?.map((child: ContentPageType) => ({
                      key: `page-${page.id}-${child.id}`,
                      label: (
                          <Link
                              to={`/content-pages/show/${child.id}`}
                              className="text-current no-underline block w-full h-full"
                              onClick={() =>
                                  setSelectedContentPageKey(
                                      `page-${page.id}-${child.id}`
                                  )
                              }
                          >
                              {child.title}
                          </Link>
                      ),
                      children:
                          child.children?.map(
                              (grandchild: ContentPageType) => ({
                                  key: `page-${page.id}-${child.id}-${grandchild.id}`,
                                  label: (
                                      <Link
                                          to={`/content-pages/show/${grandchild.id}`}
                                          onClick={() =>
                                              setSelectedContentPageKey(
                                                  `page-${page.id}-${child.id}-${grandchild.id}`
                                              )
                                          }
                                      >
                                          {grandchild.title}
                                      </Link>
                                  ),
                              })
                          ) || undefined,
                  })) || undefined,
          })) || []
        : [];

    const refineMenuItems: MenuItem[] = menuItems.map((item) => {
        return {
            key: item.key,
            icon: item.icon,
            label: item.label,
            isAdminOnly: item.meta?.adminOnly,
            onClick: () => {
                navigate(item.route || "");
            },
        };
    });

    const dividerItem =
        refineMenuItems.length > 0 && contentPagesItems.length > 0
            ? [
                  {
                      type: "divider",
                  },
              ]
            : [];


    const { t } = useTranslation();

    const { data: identity } = useGetIdentity<IIdentity>();

    const { data: userGroupData, isLoading: isUserGroupDataLoading } = useOne({
        resource: "user-groups",
        id: identity?.user_group,
        queryOptions: {
            enabled: identity?.user_group ? true : false,
        },
    });

    const items: MenuItem[] = [
        ...(contentPagesItems || []),
        ...dividerItem,
        ...refineMenuItems
    ];

    const { data: permissionsData } = usePermissions({});
    const isAdmin = permissionsData?.includes("admin");
    const currentUserItems = isAdmin ? items : items.filter(
        (item) => !item.isAdminOnly
    );

    const usageMeter = () => {
        const twoColors: ProgressProps["strokeColor"] = {
            "0%": "#3498db",
            "100%": "#e94949",
        };

        const { usage: used, monthly_usage_limit: total } =
            userGroupData?.data || {};

        const color = used >= total ? "red" : "black";

        if (isUserGroupDataLoading) {
            return null;
        }

        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                }}
            >
                <Progress
                    size="small"
                    style={{ color: "#3498db" }}
                    type="dashboard"
                    strokeColor={twoColors}
                    percent={(used / total) * 100} // 4/5 expressed as a percentage
                    format={() => (
                        <div style={{ color, fontSize: "12px" }}>
                            {used}/{total}
                        </div>
                    )}
                />
                <h3 className="mt-2">{t("stats.usage", "Usage")}</h3>
            </div>
        );
    };

    const [openKeys, setOpenKeys] = useState<string[]>(defaultOpenKeys || []);

    const handleOpenChange = (keys: string[]) => {
        const rootSubmenuKeys = items
            .filter((item): item is MenuItem => item && "key" in item)
            .map((item) => item.key as string);

        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);

        if (latestOpenKey) {
            const isRootSubmenu = rootSubmenuKeys.indexOf(latestOpenKey) !== -1;

            if (isRootSubmenu) {
                // If it's a root submenu, only open this one
                setOpenKeys([latestOpenKey]);
            } else {
                // If it's a nested submenu, keep its ancestors open
                const newOpenKeys = keys.filter(
                    (key) =>
                        latestOpenKey.startsWith(key) ||
                        key.startsWith(latestOpenKey)
                );
                setOpenKeys(newOpenKeys);
            }
        } else {
            // If closing, remove only the closed key
            const closedKey = openKeys.find((key) => keys.indexOf(key) === -1);
            if (closedKey) {
                setOpenKeys(openKeys.filter((key) => key !== closedKey));
            } else {
                setOpenKeys(keys);
            }
        }
    };

    const renderMenu = () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const sanitizedItems = currentUserItems.map(({ isAdminOnly, ...item }) => item);
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Menu
                    selectedKeys={
                        isContentPageSelected && selectedContentPageKey
                            ? [selectedContentPageKey]
                            : selectedKey
                            ? [selectedKey]
                            : []
                    }
                    openKeys={openKeys}
                    onOpenChange={handleOpenChange}
                    mode="inline"
                    style={{
                        paddingTop: "8px",
                        border: "none",
                        overflow: "auto",
                        flex: 1,
                    }}
                    onClick={() => {
                        setMobileSiderOpen(false);
                    }}
                    items={sanitizedItems}
                />
            </div>
        );
    };

    const renderDrawerSider = () => {
        return (
            <>
                <Drawer
                    open={mobileSiderOpen}
                    onClose={() => setMobileSiderOpen(false)}
                    placement="left"
                    closable={false}
                    width={200}
                    styles={{
                        body: {
                            padding: 0,
                        },
                    }}
                    maskClosable={true}
                >
                    <Layout>
                        <Layout.Sider
                            style={{
                                height: "100vh",
                                backgroundColor: token.colorBgContainer,
                                borderRight: `1px solid ${token.colorBgElevated}`,
                            }}
                        >
                            <div
                                style={{
                                    width: "200px",
                                    padding: "0 16px",
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    height: "64px",
                                    backgroundColor: token.colorBgElevated,
                                }}
                            >
                                <RenderToTitle collapsed={false} />
                            </div>
                            {renderMenu()}
                        </Layout.Sider>
                    </Layout>
                </Drawer>
            </>
        );
    };

    if (isMobile) {
        return renderDrawerSider();
    }

    const siderStyles: React.CSSProperties = {
        backgroundColor: token.colorBgContainer,
        borderRight: `1px solid ${token.colorBgElevated}`,
    };

    if (fixed) {
        siderStyles.position = "fixed";
        siderStyles.top = 0;
        siderStyles.height = "100vh";
        siderStyles.zIndex = 999;
    }

    return (
        <>
            {fixed && (
                <div
                    style={{
                        width: siderCollapsed ? "80px" : "200px",
                        transition: "all 0.2s",
                    }}
                />
            )}
            <Layout.Sider
                style={siderStyles}
                collapsible
                collapsed={siderCollapsed}
                onCollapse={(collapsed, type) => {
                    if (type === "clickTrigger") {
                        setSiderCollapsed(collapsed);
                    }
                }}
                collapsedWidth={80}
                breakpoint="lg"
                trigger={null}
            >
                <div className="flex flex-col h-full">
                    <div
                        style={{
                            width: siderCollapsed ? "80px" : "200px",
                            padding: siderCollapsed ? "0" : "0 16px",
                            display: "flex",
                            justifyContent: siderCollapsed
                                ? "center"
                                : "flex-start",
                            alignItems: "center",
                            height: "64px",
                            backgroundColor: token.colorBgElevated,
                            fontSize: "14px",
                            marginTop: "12px",
                        }}
                    >
                        <RenderToTitle collapsed={siderCollapsed} />
                    </div>
                    {renderMenu()}
                    <div className="sticky flex flex-col justify-end h-full">
                        <div className="sticky bottom-10">{usageMeter()}</div>
                        <Button
                            type="text"
                            style={{
                                position: "sticky",
                                bottom: 0,
                                borderRadius: 0,
                                height: "40px",
                                width: "100%",
                                backgroundColor: token.colorBgElevated,
                            }}
                            onClick={() => setSiderCollapsed(!siderCollapsed)}
                        >
                            {siderCollapsed ? (
                                <RightOutlined
                                    style={{ color: token.colorPrimary }}
                                />
                            ) : (
                                <LeftOutlined
                                    style={{ color: token.colorPrimary }}
                                />
                            )}
                        </Button>
                    </div>
                </div>
            </Layout.Sider>
        </>
    );
};
