import React, { useState, useEffect } from "react";
import { Typography, Card, Button } from "antd";
import { isMobile, isAndroid, isIOS } from "react-device-detect";
import { useCurrentTenant } from "tenants";
import { useApiUrl, useCustom, usePermissions, useTranslate } from "@refinedev/core";
import ReactMarkdown from "react-markdown";
import { useNavigate } from "react-router-dom";

const { Title, Paragraph } = Typography;

const InfoPage: React.FC = () => {
    const [showAddToHomeScreen, setShowAddToHomeScreen] = useState(false);
    const { title } = useCurrentTenant();
    useEffect(() => {
        setShowAddToHomeScreen(isMobile && (isAndroid || isIOS));
    }, []);

    const t = useTranslate();

    const apiUrl = useApiUrl();
    const { data: settings, isLoading } = useCustom({
        url: `${apiUrl}/settings`,
        method: "get",
    });


    const { data: permissionsData } = usePermissions({});
    const isAdmin = permissionsData?.includes("admin");

    const navigate = useNavigate();

    if (isLoading) {
        return <div>Loading...</div>;
    }

    const { app_description, disclaimer_long } =
        settings?.data || {};

    const renderAddToHomeScreenInstructions = () => {
        const benefits = (
            <Paragraph>
                {t(
                    "info.addToHomeScreen.benefits",
                    "Adding {{title}} to your home screen allows you to use it like a native app. This means:",
                    { title }
                )}
                <ul className="list-disc pl-5 mt-2">
                    <li>
                        {t(
                            "info.addToHomeScreen.benefit1",
                            "Quicker access to {{title}}",
                            { title }
                        )}
                    </li>
                    <li>
                        {t(
                            "info.addToHomeScreen.benefit2",
                            "A full-screen experience without browser controls"
                        )}
                    </li>
                    <li>
                        {t(
                            "info.addToHomeScreen.benefit3",
                            "Faster load times and improved performance"
                        )}
                    </li>
                </ul>
            </Paragraph>
        );

        if (isAndroid) {
            return (
                <>
                    <Title level={4}>
                        {t(
                            "info.addToHomeScreen.android.title",
                            "Add {{title}} to Home Screen (Android)",
                            { title }
                        )}
                    </Title>
                    {benefits}
                    <Paragraph>
                        {t(
                            "info.addToHomeScreen.android.step1",
                            "1. Tap the menu icon (3 dots) in the top right of the browser"
                        )}
                        <br />
                        {t(
                            "info.addToHomeScreen.android.step2",
                            '2. Tap "Add to Home screen"'
                        )}
                        <br />
                        {t(
                            "info.addToHomeScreen.android.step3",
                            '3. Choose a name for the app and tap "Add"'
                        )}
                    </Paragraph>
                </>
            );
        } else if (isIOS) {
            return (
                <>
                    <Title level={4}>
                        {t(
                            "info.addToHomeScreen.ios.title",
                            "Add {{title}} to Home Screen (iOS)",
                            { title }
                        )}
                    </Title>
                    {benefits}
                    <Paragraph>
                        {t(
                            "info.addToHomeScreen.ios.step1",
                            "1. Tap the share icon at the bottom of the browser"
                        )}
                        <br />
                        {t(
                            "info.addToHomeScreen.ios.step2",
                            '2. Scroll down and tap "Add to Home Screen"'
                        )}
                        <br className="mb-1" />
                        {t(
                            "info.addToHomeScreen.ios.step3",
                            '3. Choose a name for the app and tap "Add"'
                        )}
                    </Paragraph>
                </>
            );
        }
    };

    return (
        <div className="p-4 max-w-3xl mx-auto">
            <div className="flex justify-between items-center mb-4">
                <Title level={2}>{t("info.title", "Information")}</Title>
                {isAdmin && (
                    <Button
                        type="primary"
                        onClick={() => navigate("/information/edit")}
                        className="ml-4"
                    >
                        {t("info.edit.text", "Edit")}
                    </Button>
                )}
            </div>

            <Card className="mb-4">
                <Title level={4}>
                    {t("info.about", "About {{title}}", { title })}
                </Title>
                <ReactMarkdown>{app_description}</ReactMarkdown>
            </Card>

            <Card className="mb-4">
                <Title level={4}>
                    {t("info.disclaimer.title", "Disclaimer")}
                </Title>
                <ReactMarkdown>{disclaimer_long}</ReactMarkdown>
            </Card>

            {showAddToHomeScreen && (
                <Card className="mb-4">
                    {renderAddToHomeScreenInstructions()}
                </Card>
            )}
        </div>
    );
};

export default InfoPage;
