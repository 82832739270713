import Markdown from "react-markdown";
import "../../components/markdown.css";
import { useShow } from "@refinedev/core";
import { MultipleChoiceAnswerType, MultipleChoiceQuestionType } from "./types";
import { Button, Card, Radio, RadioChangeEvent } from "antd";
import { useState, useEffect } from "react";
import { ContentType } from "components/assistant";
import { ShowContentAssistant } from "../../components/layout/show-content-assistant";
import { AssistantEvent } from "./types";
const MultipleChoiceQuestion = ({
    id,
    selectedAnswer,
    setSelectedAnswer,
    checkState,
    hintCallback,
    explainCallback,
}: {
    id: number;
    selectedAnswer: MultipleChoiceAnswerType;
    setSelectedAnswer: (value: MultipleChoiceAnswerType | null) => void;
    checkState: "correct" | "incorrect" | "unanswered";
    hintCallback: (id: number) => void;
    explainCallback: (questionUuid: string, answerUuid: string) => void;
}) => {
    const { queryResult } = useShow({
        resource: "multiple-choice-questions",
        id,
    });
    const { data, isLoading } = queryResult;

    const record = data?.data;

    const handleChange = (e: RadioChangeEvent) => {
        const answer = e.target.value;
        setSelectedAnswer(answer);
    };

    if (isLoading || !record) {
        return null;
    }

    return (
        <div className="mb-8">
            <div className="flex justify-between">
                <h3>{record.title}</h3>
                <div className="flex-1" />
                {checkState === "unanswered" && (
                    <Button
                        size="small"
                        className="mr-4"
                        onClick={() => hintCallback(id)}
                    >
                        Hint
                    </Button>
                )}
                {checkState === "correct" && (
                    <p style={{ color: "green" }}>Correct!</p>
                )}
                {checkState === "incorrect" && (
                    <p style={{ color: "red" }}>Incorrect!</p>
                )}
                <div className="mr-4" />
                {(checkState === "correct" || checkState === "incorrect") && (
                    <Button
                        type="primary"
                        className="mr-4"
                        size="small"
                        onClick={() =>
                            explainCallback(record.uuid, selectedAnswer.uuid)
                        }
                    >
                        Explain
                    </Button>
                )}
            </div>
            <p>{record.content}</p>
            <Radio.Group
                value={selectedAnswer}
                onChange={handleChange}
                style={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                {record.answers.map((answer: MultipleChoiceAnswerType) => (
                    <Radio key={answer.id} value={answer} className="mb-2">
                        {answer.answer}
                    </Radio>
                ))}
            </Radio.Group>
        </div>
    );
};

export const QuizShow = () => {
    const { queryResult } = useShow({});
    const { data, isLoading } = queryResult;

    const record = data?.data;

    const [selectedAnswers, setSelectedAnswers] = useState<{
        [key: string]: MultipleChoiceAnswerType | null;
    }>({});

    const [checkStates, setCheckStates] = useState<{
        [key: string]: "correct" | "incorrect" | "unanswered";
    }>({});

    const [onAssistantEvent, setOnAssistantEvent] =
        useState<AssistantEvent>(null);

    useEffect(() => {
        if (!record) {
            return;
        }
        setSelectedAnswers(
            record.question_ids.reduce((acc: null[], questionId: number) => {
                acc[questionId] = null;
                return acc;
            }, {})
        );
        setCheckStates(
            record.question_ids.reduce((acc: string[], questionId: number) => {
                acc[questionId] = "unanswered";
                return acc;
            }, {})
        );
    }, [record]);

    const handleAnswerSelect = (
        questionId: number,
        answer: MultipleChoiceAnswerType | null
    ) => {
        setSelectedAnswers((prev) => ({ ...prev, [questionId]: answer }));
    };

    const handleCheckAll = () => {
        setCheckStates((prev) => {
            const newCheckStates = { ...prev };
            for (const questionId in selectedAnswers) {
                newCheckStates[questionId] = selectedAnswers[questionId]
                    ?.is_correct
                    ? "correct"
                    : "incorrect";
            }
            return newCheckStates;
        });
    };

    const handleExplain = (questionUuid: string, answerUuid: string) => {
        const question: MultipleChoiceQuestionType = record?.questions.find(
            (question: MultipleChoiceQuestionType) =>
                question.uuid === questionUuid
        );
        setOnAssistantEvent({
            type: "explain",
            payload: {
                questionUuid,
                question: question.content,
                answer: question.answers.find((answer) => answer.is_correct)
                    ?.answer,
                questionTitle: question.title,
                answerUuid: answerUuid,
            },
        });
    };

    const handleHint = (id: number) => {
        const question: MultipleChoiceQuestionType = record?.questions.find(
            (question: MultipleChoiceQuestionType) => question.id === id
        );
        setOnAssistantEvent({
            type: "hint",
            payload: {
                question: question.content,
                questionTitle: question.title,
                questionUuid: question.uuid,
            },
        });
    };

    if (isLoading || !record) {
        return null;
    }

    return (
        <ShowContentAssistant
            title={record.name}
            breadcrumb={""}
            contentType={ContentType.QUIZ}
            contentId={record.id}
            assistantId={record.assistant}
            assistantUuid={record.uuid}
            onAssistantEvent={onAssistantEvent}
        >
            <Card>
                <div className="overflow-y-auto" style={{ height: "70vh" }}>
                    <Markdown className="mb-8">{record.description}</Markdown>

                    {record?.question_ids.map((questionId: number) => (
                        <MultipleChoiceQuestion
                            key={questionId}
                            id={questionId}
                            checkState={checkStates[questionId]}
                            hintCallback={handleHint}
                            explainCallback={handleExplain}
                            selectedAnswer={
                                selectedAnswers[questionId] || {
                                    id: -1,
                                    uuid: "",
                                    answer: "",
                                    is_correct: false,
                                }
                            }
                            setSelectedAnswer={(answer) =>
                                handleAnswerSelect(questionId, answer)
                            }
                        />
                    ))}
                </div>
                <div className="flex justify-end">
                    <Button
                        onClick={handleCheckAll}
                        type="primary"
                        className="mr-2"
                        disabled={Object.values(selectedAnswers).some(
                            (answer) => answer === null
                        )}
                    >
                        Check All
                    </Button>
                </div>
            </Card>
        </ShowContentAssistant>
    );
};
