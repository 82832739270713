import { usePermissions, useShow } from "@refinedev/core";
import { Typography, Row, Col, Card, Breadcrumb } from "antd";
import { YooptaContentValue } from "@yoopta/editor";
import styles from "./ContentPage.module.css";
import StarterKit from "@yoopta/starter-kit";
import { ContentPageType } from "./types";
import { Link, useParams } from "react-router-dom";
import { CreateChildPageButton } from "./edit";
import { ContentType } from "components/assistant";
import { ShowContentAssistant } from "components/layout/show-content-assistant";
import { DeleteButton } from "@refinedev/antd";

const { Title } = Typography;

export const ContentPageShow = () => {
    const { id } = useParams<{ id: string }>();
    const { queryResult } = useShow({
        resource: "content-pages",
        id,
    });
    const { data, isLoading } = queryResult;
    const record = data?.data;

    const { data: permissionsData } = usePermissions({});
    const isAdmin = permissionsData?.includes("admin");

    const extraHeaderButtons = (
        <>
            {isAdmin && (
                <>
                    <DeleteButton />
                    <CreateChildPageButton
                        key="create-child"
                        currentPage={queryResult?.data?.data}
                    />
                </>
            )}
        </>
    );

    const breadcrumbItems = [
        { key: "home", title: <Link to="/content-pages">Home</Link> },
        ...(record?.breadcrumbs?.map(
            (crumb: { id?: string; title: string }, index: number) => ({
                key: crumb.id || `crumb-${index}`,
                title: crumb.id ? (
                    <Link to={`/content-pages/show/${crumb.id}`}>
                        {crumb.title}
                    </Link>
                ) : (
                    crumb.title
                ),
            })
        ) || []),
        { key: "current", title: record?.title },
    ];

    if (isLoading) {
        return <div>Loading...</div>;
    }

    const Content = () => (
        <div>
            <Card className="p-0 m-0">
                <div className="content-wrapper">
                    <StarterKit
                        value={record?.content as YooptaContentValue}
                        onChange={() => {}}
                        style={{ width: "100%" }}
                        readOnly
                    />
                </div>
            </Card>
            <div className={styles.childPagesSection}>
                {record?.children && <Title level={4}>Child Pages</Title>}
                <Row gutter={[16, 16]} className="w-full">
                    {record?.children?.map((child: ContentPageType) => (
                        <Col key={child.id} xs={24} sm={24} md={12} lg={8}>
                            <Link to={`/content-pages/show/${child.id}`}>
                                <Card
                                    hoverable
                                    className={styles.childPageCard}
                                    cover={
                                        <div
                                            className={
                                                styles.thumbnailContainer
                                            }
                                        >
                                            <img
                                                alt={child.title}
                                                src={
                                                    child.thumbnail ||
                                                    `https://picsum.photos/seed/${child.id}/300/200`
                                                }
                                                className={styles.thumbnail}
                                            />
                                        </div>
                                    }
                                >
                                    <Card.Meta title={child.title} />
                                </Card>
                            </Link>
                        </Col>
                    ))}
                </Row>
            </div>
        </div>
    );

    return (
        <ShowContentAssistant
            // title={record?.title}
            title={""}
            contentType={ContentType.PAGE}
            contentId={record.id}
            assistantId={record.assistant_id}
            assistantUuid={record.uuid}
            breadcrumb={<Breadcrumb items={breadcrumbItems} />}
            extraHeaderButtons={extraHeaderButtons}
            desktopDisplay={record.assistantDesktopDisplay || "modal"}
        >
            <Content />
        </ShowContentAssistant>
    );
};
