import Markdown from "react-markdown";
import "../../components/markdown.css";
import { EditButton, Show, useForm } from "@refinedev/antd";
import { useShow } from "@refinedev/core";
import { CaseQuestionType } from "./types";
import { Button, Card, notification, Tabs, Divider, Form, Input } from "antd";
import { useState } from "react";
import { Assistant, ContentType } from "components/assistant";
import { PanelGroup, Panel, PanelResizeHandle } from "react-resizable-panels";
import { CopyFilled, MessageOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const CaseQuestion = ({ question }: { question: CaseQuestionType }) => {
    const { formProps } = useForm({
        autoSave: {
            enabled: true,
            debounce: 500,
        },
        resource: "case-answers",
    });

    return (
        <div>
            {/* <h3>Question</h3> */}
            <Markdown>{question.question}</Markdown>
            <Divider />
            <Form {...formProps} requiredMark={false}>
                <Form.Item
                    label={<h3>Answer</h3>}
                    name="answer"
                    rules={[{ required: true }]}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                >
                    {/* Text area input */}
                    <Input.TextArea
                        autoSize={{ minRows: 5, maxRows: 16 }}
                        placeholder="Enter your answer"
                    />
                </Form.Item>
            </Form>
        </div>
    );
};

export const CaseShow = () => {
    const { queryResult } = useShow({});
    const { data, isLoading } = queryResult;

    const { t } = useTranslation();

    const record = data?.data;

    const [isAssistantVisible, setAssistantVisible] = useState(true);

    const copyQuizPublicLink = () => {
        const publicLink = `${window.location.origin}/case-public/show/${data?.data.uuid}`;
        navigator.clipboard.writeText(publicLink);
        notification.success({
            message: "Public link copied to clipboard",
            description: publicLink,
            duration: 2,
        });
    };

    if (isLoading || !record) {
        return null;
    }

    return (
        <div
            style={{
                height: "80vh",
            }}
        >
            <Show
                breadcrumb
                title={record.name}
                isLoading={isLoading}
                contentProps={{
                    style: {
                        backgroundColor: "transparent",
                        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0)",
                        margin: "-24px",
                        marginTop: "-16px",
                    },
                }}
                headerButtons={({ editButtonProps }) => {
                    return (
                        <>
                            <Button
                                size="middle"
                                onClick={copyQuizPublicLink}
                                icon={<CopyFilled />}
                            >
                                {t(
                                    "actions.copyPublicLink",
                                    "Copy Public Link"
                                )}
                            </Button>
                            <EditButton {...editButtonProps} />
                            <Button
                                type="primary"
                                onClick={() =>
                                    setAssistantVisible(!isAssistantVisible)
                                }
                                icon={<MessageOutlined />}
                            >
                                {t("assistant.toggleTutor", "Toggle Tutor")}
                            </Button>
                        </>
                    );
                }}
            >
                <PanelGroup direction="horizontal">
                    <Panel id="P-quiz" minSize={40} defaultSize={60}>
                        <Card>
                            <div
                                className="overflow-y-auto"
                                style={{ height: "70vh" }}
                            >
                                <h3>Problem Statement</h3>
                                <Markdown className="mb-8">
                                    {record.description}
                                </Markdown>
                                <Tabs
                                    defaultActiveKey="1"
                                    size="middle"
                                    type="card"
                                    items={record?.questions?.map(
                                        (question: CaseQuestionType) => ({
                                            key: question.id,
                                            label: question.name,
                                            children: (
                                                <CaseQuestion
                                                    question={question}
                                                />
                                            ),
                                        })
                                    )}
                                />
                            </div>
                        </Card>
                    </Panel>
                    {isAssistantVisible && (
                        <>
                            <PanelResizeHandle />
                            <Panel id="P-assistant" minSize={30}>
                                <Assistant
                                    assistantId={record.assistant}
                                    contentType={ContentType.CASE}
                                    contentId={record.id}
                                />
                            </Panel>
                        </>
                    )}
                </PanelGroup>
            </Show>
        </div>
    );
};
