import { useShow } from "@refinedev/core";
import ReactPlayer from "react-player";
import { Card } from "antd";
import { ContentType } from "components/assistant";
import { ShowContentAssistant } from "../../components/layout/show-content-assistant";
import "../../components/markdown.css";

type IContentVideo = {
    id: number;
    uuid: string;
    title: string;
    content: string;
    createdAt: string;
    updatedAt: string;
    video: string;
    assistant: number;
};

export const ContentVideoShow = () => {
    const { queryResult } = useShow<IContentVideo>();
    const { data, isLoading } = queryResult;

    if (isLoading) {
        return <div>Loading...</div>;
    }

    const record = data?.data;

    return (
        <ShowContentAssistant
            title={record?.title || ""}
            breadcrumb={""}
            contentType={ContentType.VIDEO}
            contentId={record?.id || 0}
            assistantId={record?.assistant || 0}
            assistantUuid={record?.uuid || ""}
        >
            <Card className="flex justify-center items-center">
                {record?.video ? (
                    <ReactPlayer
                        url={record.video}
                        controls
                        width="100%"
                    />
                ) : (
                    <div>No video uploaded</div>
                )}
            </Card>
        </ShowContentAssistant>
    );
};
