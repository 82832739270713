import React from "react";
import { useList, useGetIdentity, useInvalidate } from "@refinedev/core";
import { Table, Card, Row, Col, Space, Tag } from "antd";
import {
    DeleteButton,
    EditButton,
    ShowButton,
    useTable,
} from "@refinedev/antd";
import { useTranslation } from "react-i18next";
import { ContentList } from "components/layout/content-list";
import { IIdentity } from "../../authProvider";
import { CloneInstanceButton } from "./form";
import { QuizType } from "./types";
import { Link } from "react-router-dom";

const QuizCardList: React.FC = () => {
    const { data, isLoading } = useList<QuizType>({
        resource: "quizzes",
    });
    const { t } = useTranslation();

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="p-4">
            <Row gutter={[16, 16]}>
                {data?.data.map((quiz: QuizType) => {
                    const hasTrial = quiz.has_trial;
                    const trialExpirationDate = quiz.trial_expiration_date;
                    const hasExpired =
                        trialExpirationDate &&
                        new Date(trialExpirationDate) < new Date();

                    return (
                        <Col key={quiz.id} xs={24} sm={12} md={8} lg={8} xl={8}>
                            <Card
                                hoverable
                                cover={
                                    <img
                                        alt={quiz.name}
                                        src={`https://picsum.photos/seed/${quiz.id}/300/200`}
                                        className="h-48 object-cover"
                                    />
                                }
                            >
                                <Card.Meta
                                    title={quiz.name}
                                    description={`${
                                        quiz.question_ids?.length || 0
                                    } ${t("quiz.questions", "Questions")}`}
                                />
                                {hasTrial && !hasExpired && (
                                    <Tag color="blue" className="mt-4">
                                        {t(
                                            "document.trial.expires",
                                            "Trial expires"
                                        )}{" "}
                                        {trialExpirationDate
                                            ? new Date(
                                                  trialExpirationDate
                                              ).toLocaleDateString("sl-SI")
                                            : "N/A"}
                                    </Tag>
                                )}
                            </Card>
                            {!hasExpired && (
                                <Link
                                    to={`/quizzes/show/${quiz.id}`}
                                    className="absolute top-0 left-0 right-0 bottom-0"
                                />
                            )}
                        </Col>
                    );
                })}
            </Row>
        </div>
    );
};

const QuizTableList: React.FC = () => {
    const { tableProps } = useTable<QuizType>({
        syncWithLocation: true,
        resource: "quizzes",
    });
    const { data: identity } = useGetIdentity<IIdentity>();
    const { t } = useTranslation();
    const invalidate = useInvalidate();

    if (!identity) {
        return <div>Loading...</div>;
    }

    const columns = [
        { title: t("fields.id", "ID"), dataIndex: "id", key: "id" },
        { title: t("fields.name", "Name"), dataIndex: "name", key: "name" },
        {
            title: t("fields.questions", "Questions"),
            dataIndex: "question_ids",
            key: "questions",
            render: (question_ids: string[]) => question_ids?.length || 0,
        },
        {
            title: t("tableHeaders.actions"),
            key: "actions",
            render: (_: React.ReactNode, record: QuizType) => (
                <Space>
                    <CloneInstanceButton
                        hideText
                        resource="quizzes"
                        id={record.id.toString()}
                        className=""
                        onClick={() => {
                            invalidate({
                                resource: "quizzes",
                                invalidates: [
                                    "resourceAll",
                                    "all",
                                    "logList",
                                    "list",
                                ],
                            });
                        }}
                    />
                    <ShowButton
                        hideText
                        size="small"
                        recordItemId={record.id}
                    />
                    <EditButton
                        hideText
                        size="small"
                        recordItemId={record.id}
                    />
                    <DeleteButton
                        hideText
                        size="small"
                        recordItemId={record.id}
                    />
                </Space>
            ),
        },
    ];

    return <Table {...tableProps} columns={columns} rowKey="id" />;
};

export const QuizList: React.FC = () => {
    return (
        <ContentList
            cardComponent={QuizCardList}
            tableComponent={QuizTableList}
            cookieKey="quizzes-view"
        />
    );
};
