import {
    DeleteButton,
    Edit,
    EditButton,
    SaveButton,
    ShowButton,
    useForm,
    useTable,
} from "@refinedev/antd";
import { Card, Collapse, Form, Select, Space, Table, Tag } from "antd";
import { UserGroupForm } from "./form";
import { UserGroupData } from "./types";
import { RegistrationTokenTable } from "components/registration-tokens/table";
import AccessTable from "components/access/table";
import { useList, useCreate, BaseRecord, CrudFilters } from "@refinedev/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCurrentTenant } from "tenants";
import {
    Feature,
    mapTenantFeaturesToChoices,
} from "utilities/availableFeatures";
import { InvitedUsersTable } from "components/user-groups/user-invites";

const AvailableFeatureSelect = ({ userGroupId }: { userGroupId: number }) => {
    const { t } = useTranslation();
    interface FeatureChoices {
        value: string;
        label: string;
    }

    interface UserGroupFeature {
        id: number;
        user_group_id: number;
        name: string;
    }

    const { mutate: mutateCreate } = useCreate();

    const { data: featureChoices, isLoading } = useList<FeatureChoices>({
        resource: "feature-choices",
    });

    const { data: currentAvailableFeatures } = useList({
        resource: `user-group-features/${userGroupId}`,
        queryOptions: {
            enabled: !!userGroupId,
        },
    });

    const handleChange = (value: string[]) => {
        mutateCreate({
            resource: "user-group-features",
            values: {
                user_group_id: userGroupId,
                features: value,
            },
        });
    };

    const { features: tenantFeatures } = useCurrentTenant();
    const mappedTenantFeatures = mapTenantFeaturesToChoices(tenantFeatures);
    const filteredFeatureChoices = featureChoices?.data?.filter(
        (feature: FeatureChoices) =>
            mappedTenantFeatures?.includes(feature.value as Feature)
    );
    const translatedFeatureChoices = filteredFeatureChoices?.map(
        (feature: FeatureChoices) => ({
            ...feature,
            label: t(`features.${feature.value}`, feature.value),
        })
    );

    const [defaultValues, setDefaultValues] = useState(null);
    useEffect(() => {
        if (currentAvailableFeatures?.data && featureChoices?.data) {
            const defaultValues = featureChoices?.data?.filter(
                (feature: FeatureChoices) =>
                    currentAvailableFeatures.data.some(
                        (currentFeature: UserGroupFeature) =>
                            currentFeature.name === feature.value
                    )
            );
            // Translate the default values
            const translatedDefaultValues = defaultValues?.map(
                (feature: FeatureChoices) => ({
                    ...feature,
                    label: t(`features.${feature.value}`, feature.value),
                })
            );
            setDefaultValues(translatedDefaultValues || []);
        }
    }, [currentAvailableFeatures, featureChoices, t]);

    if (
        isLoading ||
        !featureChoices ||
        !featureChoices.data ||
        !defaultValues
    ) {
        return <div>Loading...</div>;
    }

    if (!tenantFeatures.includes(Feature.GroupBasedCustomization)) {
        return null;
    }

    return (
        <div>
            <Form>
                <Form.Item
                    label={t(
                        "userGroupForm.fields.availableFeatures",
                        "Available Features"
                    )}
                    name={["available_features"]}
                    rules={[{ required: false }]}
                    initialValue={defaultValues}
                >
                    <Select
                        mode="multiple"
                        options={translatedFeatureChoices}
                        onChange={handleChange}
                    />
                </Form.Item>
            </Form>
        </div>
    );
};

export const UserGroupUsers = ({ userGroupId }: { userGroupId: number }) => {
    const { t } = useTranslation();

    const { tableProps } = useTable({
        syncWithLocation: true,
        resource: "users",
        filters: {
            permanent: [
                {
                    field: "user_group",
                    operator: "eq",
                    value: userGroupId,
                },
            ] as CrudFilters,
        },
    });

    return (
        <Card
            title={t("userGroupForm.fields.allGroupUsers", "All Group Users")}
            styles={{
                body: {
                    padding: 0,
                },
            }}
        >
            <Table {...tableProps} rowKey="id">
                <Table.Column
                    dataIndex="id"
                    title={t("tableHeaders.id", "ID")}
                />
                <Table.Column
                    dataIndex="name"
                    title={t("tableHeaders.name", "Name")}
                />
                <Table.Column
                    dataIndex="email"
                    title={t("tableHeaders.email", "Email")}
                />
                <Table.Column
                    dataIndex="usage"
                    title={t("tableHeaders.usage", "Usage")}
                />
                <Table.Column
                    title={t("tableHeaders.actions", "Actions")}
                    dataIndex="actions"
                    align="right"
                    render={(_, record: BaseRecord) => {
                        return (
                            <Space>
                                <EditButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                />
                                <ShowButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                />
                                <DeleteButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                />
                            </Space>
                        );
                    }}
                />
            </Table>
        </Card>
    );
};

export const UserGroupEdit = () => {
    const { formProps, saveButtonProps, queryResult, formLoading } = useForm(
        {
            redirect: false,

        }
    );

    const { t } = useTranslation();

    return (
        <Edit
        footerButtons={[]}
        headerButtons={[
            <DeleteButton key="delete-button" />,
            <SaveButton {...saveButtonProps} />,
        ]}
        >
            <UserGroupForm
                formProps={formProps}
                data={queryResult?.data?.data as UserGroupData | undefined}
            />
            <Collapse className="mb-4">
                <Collapse.Panel
                    key="1"
                    header={t(
                        "userGroupForm.fields.userManagement",
                        "User Management"
                    )}
                >
                    <UserGroupUsers
                        userGroupId={parseInt(queryResult?.data?.data?.id)}
                    />
                    <InvitedUsersTable
                        userGroupId={parseInt(queryResult?.data?.data?.id)}
                        isEditable
                    />
                    <RegistrationTokenTable
                        userGroupId={parseInt(queryResult?.data?.data?.id)}
                        isEditable
                    />
                </Collapse.Panel>
            </Collapse>
            <Collapse>
                <Collapse.Panel
                    key="2"
                    header={t(
                        "userGroupForm.fields.availableFeatures",
                        "Available Features"
                    )}
                >
                    <AvailableFeatureSelect
                        userGroupId={parseInt(queryResult?.data?.data?.id)}
                    />
                    <AccessTable
                        userGroupId={parseInt(queryResult?.data?.data?.id)}
                        isEditable
                    />
                </Collapse.Panel>
            </Collapse>
        </Edit>
    );
};
