import React from "react";
import { useTable } from "@refinedev/antd";
import { useList } from "@refinedev/core";
import { ContentList } from "components/layout/content-list";
import { Table, Card, Row, Col, Space, Tag } from "antd";
import { DeleteButton, EditButton, ShowButton } from "@refinedev/antd";
import { useTranslation } from "react-i18next";
import { ContentVideoType } from "./types";
import { Link } from "react-router-dom";

const VideoCardList: React.FC = () => {
    const { data, isLoading } = useList<ContentVideoType>({
        resource: "content-videos",
    });

    const { t } = useTranslation();

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="p-4">
            <Row gutter={[16, 16]}>
                {data?.data.map((video: ContentVideoType) => {
                    const hasTrial = video.has_trial;
                    const trialExpirationDate = video.trial_expiration_date;
                    const hasExpired =
                        trialExpirationDate &&
                        new Date(trialExpirationDate) < new Date();

                    return (
                        <Col
                            key={video.id}
                            xs={24}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={8}
                        >
                            <Card
                                hoverable
                                cover={
                                    <img
                                        alt={video.title}
                                        src={
                                            video.thumbnail ||
                                            `https://picsum.photos/seed/${video.id}/300/200`
                                        }
                                        className="h-48 object-cover"
                                    />
                                }
                            >
                                <Card.Meta
                                    title={video.title}
                                />
                                {hasTrial && !hasExpired && (
                                    <Tag color="blue" className="mt-4">
                                        {t(
                                            "document.trial.expires",
                                            "Trial expires"
                                        )}{" "}
                                        {trialExpirationDate
                                            ? new Date(
                                                  trialExpirationDate
                                              ).toLocaleDateString("sl-SI")
                                            : "N/A"}
                                    </Tag>
                                )}
                            </Card>
                            {!hasExpired && (
                                <Link
                                    to={`/content-videos/show/${video.id}`}
                                    className="absolute top-0 left-0 right-0 bottom-0"
                                />
                            )}
                        </Col>
                    );
                })}
            </Row>
        </div>
    );
};

const VideoTableList: React.FC = () => {
    const { tableProps } = useTable<ContentVideoType>({
        syncWithLocation: true,
        resource: "content-videos",
    });
    const { t } = useTranslation();

    const columns = [
        { title: t("fields.id", "ID"), dataIndex: "id", key: "id" },
        { title: t("fields.title", "Title"), dataIndex: "title", key: "title" },
        {
            title: t("fields.description", "Description"),
            dataIndex: "description",
            key: "description",
        },
        {
            title: t("tableHeaders.actions"),
            key: "actions",
            render: (_: React.ReactNode, record: ContentVideoType) => (
                <Space>
                    <ShowButton
                        hideText
                        size="small"
                        recordItemId={record.id}
                    />
                    <EditButton
                        hideText
                        size="small"
                        recordItemId={record.id}
                    />
                    <DeleteButton
                        hideText
                        size="small"
                        recordItemId={record.id}
                    />
                </Space>
            ),
        },
    ];

    return <Table {...tableProps} columns={columns} rowKey="id" />;
};

export const ContentVideoList: React.FC = () => {
    return (
        <ContentList
            cardComponent={VideoCardList}
            tableComponent={VideoTableList}
            cookieKey="content-videos-view"
        />
    );
};
