import { useState, useEffect } from "react";
import { Feature } from "../utilities/availableFeatures";

function getAllFeatureNames(): Feature[] {
    return Object.keys(Feature)
        .filter((key) => isNaN(Number(key)))
        .map((key) => Feature[key as keyof typeof Feature]);
}
const allFeatureNames = getAllFeatureNames();

export const TENANTS: {
    [key: string]: {
        features: Feature[];
        title: string;
        icon: string;
        defaultLanguage: string;
        domains: string[];
        color: string;
        font: string;
        cssFile: string;
        manifest: string;
        favicon: string;
    };
} = {
    reforma: {
        features: [
            Feature.Dashboard,
            Feature.Users,
            Feature.UserGroups,
            Feature.Documents,
            Feature.GroupBasedCustomization,
            Feature.Assistants,
            Feature.AssistantsAdmin,
            Feature.Evaluations,
        ],
        title: "Reforma AI",
        icon: "/static/tenants/reforma.png",
        favicon: "/static/tenants/reforma_128.png",
        defaultLanguage: "sl",
        domains: [
            "reforma.aceflow.io",
            "*.zalozba-reforma.si",
            "reforma.localhost",
            "reforma-prod.aceflow.io",
            "reforma-staging.aceflow.io",
        ],
        color: "#63bec0",
        font: "Open Sans",
        cssFile: "/static/tenants/reforma.css",
        manifest: "/static/tenants/reforma.json",
    },
    financeForward: {
        features: [
            Feature.Dashboard,
            Feature.Users,
            Feature.UserGroups,
            Feature.ContentPages,
            Feature.ContentPagesAdmin,
            Feature.AssistantsAdmin,
        ],
        title: "Finance Forward Academy",
        icon: "/static/tenants/finance-forward.png",
        favicon: "/static/tenants/finance-forward.png",
        defaultLanguage: "en",
        domains: [
            "finance-forward.localhost",
            "finance-forward-academy.localhost",
            "finance-forward.aceflow.io",
            "finance-forward-staging.aceflow.io",
            "finance-forward-academy.aceflow.io",
            "finance-forward-academy-staging.aceflow.io",
        ],
        color: "#3EB489",
        font: "Plus Jakarta Sans",
        cssFile: "/static/tenants/finance-forward.css",
        manifest: "/static/tenants/finance-forward.json",
    },
    crackVerbal: {
        features: [
            Feature.Dashboard,
            Feature.Users,
            Feature.UserGroups,
            // Feature.ContentPages,
            // Feature.ContentPagesAdmin,
            Feature.AssistantsAdmin,
            Feature.Quizzes,
            Feature.Videos,
            Feature.Documents,
            Feature.PublicContentLinks,
        ],
        title: "Crackverbal",
        icon: "/static/tenants/crackverbal.png",
        favicon: "/static/tenants/crackverbal.png",
        defaultLanguage: "en",
        domains: [
            "crackverbal.localhost",
            "crackverbal.aceflow.io",
            "crackverbal-staging.aceflow.io",
        ],
        color: "#0229E4",
        font: "Montserrat",
        cssFile: "/static/tenants/crackverbal.css",
        manifest: "/static/tenants/crackverbal.json",
    },
    defaultTenant: {
        features: allFeatureNames,
        title: "AceFlow",
        icon: "/static/tenants/default.png",
        favicon: "/static/tenants/default.png",
        defaultLanguage: "en",
        domains: [],
        color: "#1677FF",
        font: "Lexend Deca",
        cssFile: "/static/tenants/default.css",
        manifest: "/static/tenants/default.json",
    },
};

function matchDomain(currentDomain: string, tenantDomain: string): boolean {
    if (tenantDomain.startsWith("*.")) {
        const suffix = tenantDomain.slice(1);
        return currentDomain.endsWith(suffix);
    }
    return currentDomain.includes(tenantDomain);
}

export function useCurrentTenant() {
    const [tenant, setTenant] = useState({
        id: "",
        defaultLanguage: TENANTS.defaultTenant.defaultLanguage,
        features: TENANTS.defaultTenant.features,
        title: TENANTS.defaultTenant.title,
        icon: TENANTS.defaultTenant.icon,
        color: TENANTS.defaultTenant.color,
        font: TENANTS.defaultTenant.font,
        cssFile: TENANTS.defaultTenant.cssFile,
        manifest: TENANTS.defaultTenant.manifest,
        favicon: TENANTS.defaultTenant.favicon,
    });

    useEffect(() => {
        const currentDomain = window.location.hostname;
        const tenantId =
            Object.entries(TENANTS).find(([, config]) =>
                config.domains.some((domain) =>
                    matchDomain(currentDomain, domain)
                )
            )?.[0] || "defaultTenant";

        const tenantConfig = TENANTS[tenantId] || TENANTS.defaultTenant;

        setTenant({
            id: tenantId,
            defaultLanguage: tenantConfig.defaultLanguage,
            features: tenantConfig.features,
            title: tenantConfig.title,
            icon: tenantConfig.icon,
            color: tenantConfig.color,
            font: tenantConfig.font,
            cssFile: tenantConfig.cssFile,
            manifest: tenantConfig.manifest,
            favicon: tenantConfig.favicon,
        });
    }, []);

    useEffect(() => {
        if (tenant.id) {
            // Update favicon
            const faviconLink: HTMLLinkElement =
                document.querySelector("link[rel*='icon']") ||
                document.createElement("link");
            faviconLink.type = "image/png";
            faviconLink.rel = "shortcut icon";
            faviconLink.href = tenant.favicon;
            document.getElementsByTagName("head")[0].appendChild(faviconLink);

            // Update manifest only when tenant is loaded
            const manifestLink: HTMLLinkElement =
                document.querySelector("link[rel='manifest']") ||
                document.createElement("link");
            manifestLink.rel = "manifest";
            manifestLink.href = tenant.manifest;
            document.getElementsByTagName("head")[0].appendChild(manifestLink);
        }
    }, [tenant]);

    return tenant;
}
